<template>
  <div v-if="loaded"
       class="container"
  >
    <div v-if="error">
      <b-alert class="mt-4"
               variant="danger"
               show
      >
        <div>
          {{staticText.followingErrorOccurred}}
        </div>
        <b>
          {{error}}
        </b>
      </b-alert>
    </div>

    <div v-else
         class="mt-4"
    >

      <p>{{staticText.closeWindowLabel1}}</p>

      <p>{{staticText.closeWindowLabel2}}</p>

    </div>

    <div class="mt-3">
      <b-btn variant="primary"
             @click="closeWindow"
      >
        {{staticText.closeWindowBtn}}
      </b-btn>
    </div>

  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "OauthLoginHandler",
  data () {
    return {
      someUrl: null,
      loaded: false,
      error: null,
      staticTextDefault: {
        closeWindowLabel1: "This window should close automatically.",
        closeWindowLabel2: "If this window does not close automatically click the button below and refresh (F5) the conversation setup page.",
        closeWindowBtn: "Close window",
        followingErrorOccurred: "The following error occurred:"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  created () {
    this.oauthLoginHandler()
  },
  methods: {
    oauthLoginHandler () {
      this.updateLoginData(this.$route.query).then(response => {
        // window is closed only if oauth login was successful.
        if (response.status === 200) {
          // status 200 indicates that user has been successfully authenticated to the service with provide credentials.
          // redirect if response.data has redirect value otherwise close window.
          if (response.data.redirect) {
            this.$router.push(response.data.redirect)
            return
          }
          this.closeWindow()
        }
      },
      error => {
        this.error = error.data
        this.loaded = true
      })
    },
    closeWindow () {
      window.close()
    },
    updateLoginData (newData) {
      const url = "/api/oauthlogin/retrieve_access_token"
      return new Promise((resolve, reject) => {
        axios.put(url, newData).then(response => {
        // now the received token from CRM authentication api should be saved on our backend, so we can retrieve it later
          resolve(response)
        }).catch(error => {
          reject(error.response)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
